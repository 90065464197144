import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy'; // Import the icon

function ResultsModal({ open, onClose, data }) {
    const selectedOctaves = data?.selectedOctaves || [];
    const selectedNotes = data?.selectedNotes || [];
    const octavesDisplay = Array.isArray(data.selectedOctaves) ? data.selectedOctaves.join(', ') : '';
    const totalAnswers = data.correctAnswers + data.incorrectAnswers;
    const scorePercentage = totalAnswers > 0 ? ((data.correctAnswers / totalAnswers) * 100).toFixed(0) : 0;

    const handleShareResults = () => {
        const resultsText = `🎵 PitchCraft.me Results 🎵\n\n` +
                            `Score: ${scorePercentage}% 🎼\n` +
                            `Correct: ${data.correctAnswers} ✅\n` +
                            `Incorrect: ${data.incorrectAnswers} ❌\n` +
                            `Octaves: ${octavesDisplay} 🎹\n` +
                            `Notes: ${selectedNotes.join(', ')} 🎶\n` +
                            `Mode: ${data.mode ? data.mode : 'N/A'} 🔤`;
        
        navigator.clipboard.writeText(resultsText).then(() => {
            alert('Results copied to clipboard!');
        }, (err) => {
            console.error('Failed to copy results: ', err);
        });
    };

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 400,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                outline: 'none',
            }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Session Recap:
                </Typography>
                {data.timestamp && (
                    <Typography sx={{ mt: 2 }} className='results-timestamp'>
                     {data.timestamp}
                    </Typography>
                )}
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Correct Answers: {data.correctAnswers}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    Incorrect Answers: {data.incorrectAnswers}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    Score: {scorePercentage}% 
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    Octaves: {octavesDisplay}
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    Selected Notes: {selectedNotes.join(', ')} 
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    Mode: {data.mode ? data.mode : 'N/A'}
                </Typography>
                <Button fullWidth variant="outlined" onClick={handleShareResults} sx={{ mt: 2 }}>
                <ContentCopyIcon />  Share Results
                </Button>
                <Button  fullWidth variant="outlined" color="error" onClick={onClose} sx={{ mt: 2 }}>
                    Close
                </Button>
            </Box>
        </Modal>
    );
}

export default ResultsModal;
