const badgeConfig = {
    badgeThresholds: {
        default: {
            scores: [98, 98, 98, 100],
            notesRequired: [75, 150, 225, 300]  // Hypothetical values, adjust as necessary
        },
        sequential: {
            2: {scores: [98, 98, 98, 98], notesRequired: [35, 70, 105, 140]},
            3: {scores: [98, 98, 98, 98], notesRequired: [25, 50, 75, 100]},
            // More configurations as needed
        },
        chord: {
            2: {scores: [98, 98, 98, 98], notesRequired: [35, 70, 105, 140]},
            3: {scores: [98, 98, 98, 98], notesRequired: [25, 50, 75, 100]},
            // More configurations as needed
        }
    },
    badgeLevels: ['Bronze', 'Silver', 'Gold', 'Platinum',],
    badgeDescriptions: {
        Bronze: { description: "Reach a score of at least 50%", emoji: "🥉" },
        Silver: { description: "Reach a score of at least 70%", emoji: "🥈" },
        Gold: { description: "Reach a score of at least 85%", emoji: "🏆" },
        Platinum: { description: "Reach a score of at least 100%", emoji: "👑" },
    },
    getThresholds: (mode, noteCount) => {
        if (mode === 'default') {
            return badgeConfig.badgeThresholds.default;
        } else if (badgeConfig.badgeThresholds[mode] && badgeConfig.badgeThresholds[mode][noteCount]) {
            return badgeConfig.badgeThresholds[mode][noteCount];
        }
        return undefined;  // Fallback if no specific configuration exists
    }
};


export default badgeConfig;
