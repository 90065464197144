import { useState } from 'react';

import FormInput from '../form-input/form-input.component';
//import Button from '../button/button.component';

import {
  signInAuthUserWithEmailAndPassword,
  signInWithGooglePopup,
  createUserDocumentFromAuth,
} from '../../utils/firebase/firebase.utils';

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';


const defaultFormFields = {
  email: '',
  password: '',
};

const SignInForm = () => {
  const [formFields, setFormFields] = useState(defaultFormFields);
  const { email, password } = formFields;

  const resetFormFields = () => {
    setFormFields(defaultFormFields);
  };

  const signInWithGoogle = async () => {
    await signInWithGooglePopup();
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      await signInAuthUserWithEmailAndPassword(email, password);
      resetFormFields();
    } catch (error) {
      console.log('user sign in failed', error);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFormFields({ ...formFields, [name]: value });
  };

  return (
    <Container maxWidth="sm">
      <Box sx={{
        marginBottom: 20, marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }} >
        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <Box sx={{ mt: 1 }}>
          <form onSubmit={handleSubmit}>
            <TextField
              label='Email'
              margin="normal"
              type='email'
              required
              fullWidth
              onChange={handleChange}
              name='email'
              value={email}
              sx={{ mb: 3 }}
            />

            <TextField
              label='Password'
              type='password'
              required
              fullWidth
              onChange={handleChange}
              name='password'
              value={password}
              sx={{ mb: 3 }}
            />
            <div className='buttons-container'>
              <Button type='submit'
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >Sign In</Button>
              <Button buttonType='google' type='button'
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={signInWithGoogle}>
                Sign In With Google
              </Button>
             
            </div>
          </form>
          <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link href='/sign-up' variant="body2">
                    {"Don't have an account? Sign Up"}
                  </Link>
                </Grid>
              </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default SignInForm;
