import React from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, BarChart, Bar } from 'recharts';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography  from '@mui/material/Typography';

const DashboardCharts = ({ quizzes }) => {
  // For Bar Chart
  const scoreCategories = {
    '💯': 0,
    '🟩': 0,
    '🟨': 0,
    '💩': 0
  };

  quizzes.forEach(quiz => {
    const score = ((quiz.correctAnswers / (quiz.correctAnswers + quiz.incorrectAnswers || 1)) * 100).toFixed(0);
    const emoji = score === '100' ? '💯' : score >= '90' ? '🟩' : score >= '70' ? '🟨' : '💩';
    scoreCategories[emoji]++;
  });

  const barData = Object.keys(scoreCategories).map(key => ({
    name: key,
    value: scoreCategories[key]
  }));

  // For Line Chart
  const lineData = quizzes.map((quiz) => ({
    date: quiz.timestamp, // Assuming 'timestamp' is a string representing the date
    score: ((quiz.correctAnswers / (quiz.correctAnswers + quiz.incorrectAnswers || 1)) * 100).toFixed(0)
  }));

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
            <ResponsiveContainer  height={300}>
              <BarChart data={barData}>
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card>
          <CardContent>
          
            <ResponsiveContainer  height={300}>
              <LineChart data={lineData}>
                <XAxis dataKey="date" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="score" stroke="#8884d8" activeDot={{ r: 8 }} />
              </LineChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default DashboardCharts;
