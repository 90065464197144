// src/SoundfontProvider.js

import React from 'react';
import PropTypes from 'prop-types';
import { MidiNumbers } from 'react-piano';
import Soundfont from 'soundfont-player';

class SoundfontProvider extends React.Component {
 
 
 
  static propTypes = {
    audioContext: PropTypes.instanceOf(window.AudioContext),
    soundfontHostname: PropTypes.string.isRequired,
    instrumentName: PropTypes.string.isRequired,
    onReady: PropTypes.func.isRequired,
  };

  componentDidMount() {
    this.loadInstrument(this.props.instrumentName);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.instrumentName !== this.props.instrumentName) {
      this.loadInstrument(this.props.instrumentName);
    }
  }

  loadInstrument = (instrumentName) => {
    Soundfont.instrument(this.props.audioContext, instrumentName, {
      soundfont: 'MusyngKite',
      nameToUrl: Soundfont.nameToUrl,
      soundfontHostname: this.props.soundfontHostname,
    }).then((instrument) => {
      this.props.onReady(instrument);
    });
  };

  render() {
    return null;
  }
}

export default SoundfontProvider;
