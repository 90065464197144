import { createTheme, responsiveFontSizes } from "@mui/material";

const getTheme = (darkMode = false) => {
  let theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: darkMode ? '#9c27b0' : '#673ab7', // Purple shades for dark and light modes
      },
      secondary: {
        main: darkMode ? '#7c4dff' : '#9575cd', // Different shades for contrast
      },
      background: {
        default: darkMode ? '#121212' : '#f5f5f5',
        paper: darkMode ? '#1e1e1e' : '#ffffff',
      },
    },
    // other theme settings...
  });

  return responsiveFontSizes(theme);
};

export default getTheme;
