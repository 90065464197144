import { initializeApp } from 'firebase/app';
import {
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
 
} from 'firebase/auth';
import { getFirestore, doc, getDoc, setDoc, collection, getDocs } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyCuplFSCoFOiRWE6XO5b_JoMb9T1hcKCIY",
  authDomain: "pitchcraft---prod.firebaseapp.com",
  projectId: "pitchcraft---prod",
  storageBucket: "pitchcraft---prod.appspot.com",
  messagingSenderId: "480124098528",
  appId: "1:480124098528:web:09bf3fbdf317a6badfbd85"
};

const firebaseApp = initializeApp(firebaseConfig);

const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
  prompt: 'select_account',
});

export const auth = getAuth();
export const signInWithGooglePopup = () =>
  signInWithPopup(auth, googleProvider);
export const signInWithGoogleRedirect = () =>
  signInWithRedirect(auth, googleProvider);

export const db = getFirestore();

export const createUserDocumentFromAuth = async (userAuth, additionalInformation = {}) => {
  if (!userAuth) return;

  const userDocRef = doc(db, 'users', userAuth.uid);
  const userSnapshot = await getDoc(userDocRef);

  if (!userSnapshot.exists()) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();

    try {
      await setDoc(userDocRef, {
        displayName,
        email,
        createdAt,
        gamesPlayed: 0,
        quizzesPassed: 0,
        statCorrectNotes: 0,
        statIncorrectNotes: 0,
        statCorrectNoteStreak: 0,
        statMaxCorrectNoteStreak: 0,
        statWinStreak: 0,
        statMaxWinStreak: 0,
        levelStats: {},
        maxUnlockedLevel: 0,
        ...additionalInformation,
      });
    } catch (error) {
      console.log('error creating the user', error.message);
    }
  }

  return userDocRef;
};


export const createAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await createUserWithEmailAndPassword(auth, email, password);
};

export const signInAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await signInWithEmailAndPassword(auth, email, password);
};

export const signOutUser = async () => await signOut(auth);

export const onAuthStateChangedListener = (callback) =>
  onAuthStateChanged(auth, callback);

  export const saveQuizResult = async (userId, quizData) => {
    if (!userId || !quizData) return;
  
    const quizDocRef = doc(collection(db, 'users', userId, 'quizzes'));
  
    try {
      await setDoc(quizDocRef, {
        ...quizData,
        timestamp: new Date() // Store the current time as the timestamp
      });
    } catch (error) {
      console.error("Error saving quiz result: ", error.message);
    }
  };
  
  export const getUserQuizStats = async (userId) => {
    if (!userId) return [];
  
    const quizzesCollectionRef = collection(db, 'users', userId, 'quizzes');
    try {
      const querySnapshot = await getDocs(quizzesCollectionRef);
      return querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
    } catch (error) {
      console.error("Error fetching quiz stats: ", error.message);
      return [];
    }
  };
  