import React from 'react';
import { Card, CardContent, Typography, Box, LinearProgress } from '@mui/material';
import './NoteStreak.css'; // Ensure your styles are imported

const NoteStreakDisplay = ({ noteStreak }) => {
    const streakMilestones = {
        heatingup: 10,
        onfire: 25,
        blazing: 50,
        inferno: 100,
        unstoppable: 150
    };

    const streakFeedback = {
        unstoppable: "Unstoppable! ",
        inferno: "Inferno! ",
        blazing: "Blazing! ",
        onfire: "On Fire! ",
        heatingup: "Heating Up! ",
        none: ""
    };

    const getStreakDetails = () => {
        if (noteStreak >= streakMilestones.unstoppable) {
            return { current: 'unstoppable', emoji: '☣️', nextEmoji: '', feedback: streakFeedback.unstoppable, min: streakMilestones.inferno, max: streakMilestones.unstoppable };
        }
        if (noteStreak >= streakMilestones.inferno) {
            return { current: 'inferno', emoji: '🌋', nextEmoji: '☣️', feedback: streakFeedback.inferno, min: streakMilestones.blazing, max: streakMilestones.unstoppable };
        }
        if (noteStreak >= streakMilestones.blazing) {
            return { current: 'blazing', emoji: '❤️‍🔥', nextEmoji: '🌋', feedback: streakFeedback.blazing, min: streakMilestones.onfire, max: streakMilestones.inferno };
        }
        if (noteStreak >= streakMilestones.onfire) {
            return { current: 'onfire', emoji: '🔥', nextEmoji: '❤️‍🔥', feedback: streakFeedback.onfire, min: streakMilestones.heatingup, max: streakMilestones.blazing };
        }
        if (noteStreak >= streakMilestones.heatingup) {
            return { current: 'heatingup', emoji: '🌡️', nextEmoji: '🔥', feedback: streakFeedback.heatingup, min: 0, max: streakMilestones.onfire };
        }
        return { current: '', emoji: '❄️', nextEmoji: '🌡️', feedback: streakFeedback.none, min: 0, max: streakMilestones.heatingup };
    };
    
    

    const calculateProgress = (min, max) => {
        const range = max - min;
        const progressInLevel = noteStreak - min;
        return (progressInLevel / range) * 100;
    };
    


    // Function to determine the animation class based on streak count
    const getAnimationClass = () => {
        if (noteStreak >= streakMilestones.unstoppable) return 'unstoppable';
        if (noteStreak >= streakMilestones.inferno) return 'inferno';
        if (noteStreak >= streakMilestones.blazing) return 'blazing';
        if (noteStreak >= streakMilestones.onfire) return 'onfire';
        if (noteStreak >= streakMilestones.heatingup) return 'heatingup';
        return ''; // No animation class if below the first milestone
    };


    
    const { current, emoji: currentEmoji, nextEmoji, min, max, feedback } = getStreakDetails();
    const progress = calculateProgress(min, max);

    return (
        <Card variant="outlined" className={`note-streak ${current}`}>
            <CardContent style={{ padding: '8px' }}>
                <Typography variant="subtitle2" display="block" style={{ textAlign: 'center' }}>
                    {feedback} {currentEmoji}
                </Typography>
                <Box className="progress-container" style={{ height: '10px', margin: '8px 0' }}>
                    <LinearProgress variant="determinate" value={progress} />
                </Box>
                <Typography variant="caption" display="block" style={{ textAlign: 'center' }}>
                    Streak: {noteStreak}
                </Typography>
            </CardContent>
        </Card>
    );
};

export default NoteStreakDisplay;
