import React, { useState, useEffect, useContext } from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import InfoIcon from '@mui/icons-material/Info';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PollIcon from '@mui/icons-material/Poll';
import { Link } from 'react-router-dom';
import { Plugins, Capacitor } from '@capacitor/core';
import { UserContext } from '../contexts/user.context';
import { DarkModeContext } from '../contexts/DarkModeContext';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import { signInWithGooglePopup, signOutUser } from '../utils/firebase/firebase.utils';

const { GoogleAuth } = Plugins;

export default function PrimarySearchAppBar() {
  const { currentUser, setCurrentUser } = useContext(UserContext);

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      if (GoogleAuth) {
        GoogleAuth.init();
      } else {
        console.warn('GoogleAuth plugin is not available on the web.');
      }
    }
  }, []);

  const signInWithGoogle = async () => {
    try {
      let user;
      if (Capacitor.isNativePlatform() && GoogleAuth) {
        // Native iOS/Android sign-in
        user = await GoogleAuth.signIn();
        setCurrentUser(user);
      } else {
        // Web sign-in using Firebase
        const result = await signInWithGooglePopup();
        setCurrentUser(result.user);
      }
    } catch (error) {
      console.error('Error during Google Sign-In:', error);
    }
  };

  const signOutHandler = async () => {
    try {
      if (Capacitor.isNativePlatform() && GoogleAuth) {
        await GoogleAuth.signOut();
      } else {
        await signOutUser();
      }
      setCurrentUser(null);
    } catch (error) {
      console.error('Error during Google Sign-Out:', error);
    }
  };

  const { darkMode, setDarkMode } = useContext(DarkModeContext);

  const handleDarkModeToggle = () => {
    setDarkMode(!darkMode);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <Link to="/about" className="customLink">
        <Button size="large" aria-label="About" color="inherit">
          <InfoIcon />
          <Typography>About</Typography>
        </Button>
      </Link>
      <Link to="/stats" className="customLink">
        <Button size="large" aria-label="Stats" color="inherit">
          <PollIcon />
          <Typography>Badges</Typography>
        </Button>
      </Link>
      <MenuItem>
        {currentUser ? (
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={signOutHandler}>
            Log Out
          </Button>
        ) : (
          <Button
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={signInWithGoogle}>
            Sign In
          </Button>
        )}
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" enableColorOnDark color="default">
        <Toolbar>
          <Link to="/" className="customLink">
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{ display: { xs: 'block', sm: 'block' } }}
            >
              🎹 PitchCraft. 
            </Typography>
          </Link>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <Link to="/about" className="customLink">
              <Button size="large" aria-label="About" color="inherit">
                <InfoIcon />
                <Typography>About</Typography>
              </Button>
            </Link>
            <Link to="/stats" className="customLink">
              <Button size="large" aria-label="Stats" color="inherit">
                <PollIcon />
                <Typography>Badges</Typography>
              </Button>
            </Link>
            {currentUser ? (
              <Button
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                color="inherit"
                onClick={signOutHandler}>
                <AccountCircle /> Log Out
              </Button>
            ) : (
              <Button
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={signInWithGoogle}
                color="inherit">
                <AccountCircle /> <Typography>Log In</Typography>
              </Button>
            )}
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <IconButton sx={{ ml: 1 }} onClick={handleDarkModeToggle} color="inherit">
            {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
