import React, { createContext, useState, useEffect, useContext } from 'react';
import { UserContext } from '../contexts/user.context';
import { getFirestore, collection, doc, getDoc, setDoc, getDocs, deleteDoc, onSnapshot, query, orderBy  } from 'firebase/firestore';
import badgeConfig from '../utils/badgeConfig';

export const StatsContext = createContext();

export const StatsProvider = ({ children }) => {
  const db = getFirestore();

  const [gamesPlayed, setGamesPlayed] = useState(0);
  const [quizzesPassed, setQuizzesPassed] = useState(0);
  const [statCorrectNotes, setStatCorrectNotes] = useState(0);
  const [statIncorrectNotes, setStatIncorrectNotes] = useState(0);
  const [statCorrectNoteStreak, setStatCorrectNoteStreak] = useState(0);
  const [statMaxCorrectNoteStreak, setStatMaxCorrectNoteStreak] = useState(0);
  const [statWinStreak, setStatWinStreak] = useState(0);
  const [statMaxWinStreak, setStatMaxWinStreak] = useState(0);
  const [levelStats, setLevelStats] = useState({}); // Assuming an object
  const [maxUnlockedLevel, setMaxUnlockedLevel] = useState(0);
  const [highScore, setHighScore] = useState(0);


  const { currentUser } = useContext(UserContext);
  const userId = currentUser?.uid;

    //DELETE QUIZ 
    const [quizzes, setQuizzes] = useState([]);

    
  
    const deleteQuiz = async (quizId) => {
      if (!userId) return;
  
  
      try {
          const quizRef = doc(db, `users/${userId}/quizzes`, quizId);
          await deleteDoc(quizRef);
  
          const updatedQuizzes = quizzes.filter(quiz => quiz.id !== quizId);
  
          setQuizzes(updatedQuizzes);
      } catch (error) {
          console.error("Error deleting quiz: ", error);
      }
  };
  
    
// END DELETE QUIZ  

useEffect(() => {
  if (!userId) return;

  const quizzesRef = collection(db, `users/${userId}/quizzes`);
  const q = query(quizzesRef, orderBy("timestamp", "desc")); // Ensure correct order

  const unsubscribe = onSnapshot(q, (querySnapshot) => {
    const quizzesData = querySnapshot.docs.map(doc => {
      const data = doc.data();
      const timestamp = data.timestamp;
      // Check if timestamp is a Firestore Timestamp object before converting
      return {
        id: doc.id,
        ...data,
        timestamp: timestamp && typeof timestamp.toDate === 'function' ? timestamp.toDate().toLocaleString() : timestamp
      };
    });
    setQuizzes(quizzesData);
  });

  return () => unsubscribe(); // Cleanup the subscription on unmount
}, [userId, db]);




const checkAndAwardBadges = () => {
  if (!userId) return; // Ensure there's a user logged in

  // Example of checking a simple condition for awarding a badge
  badgeConfig.forEach((badge) => {
    if (badge.condition(gamesPlayed, statCorrectNotes, highScore, statMaxCorrectNoteStreak)) {
      // Assuming user's badges are stored in an array in their profile
      const userRef = doc(db, "users", userId);
      getDoc(userRef).then((docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          let newBadges = userData.badges || [];
          if (!newBadges.includes(badge.id)) {
            newBadges.push(badge.id);
            setDoc(userRef, { badges: newBadges }, { merge: true });
          }
        }
      });
    }
  });
};





  useEffect(() => {
    if (!userId) return;

    const userRef = doc(db, "users", userId);
    getDoc(userRef).then(docSnapshot => {
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        setGamesPlayed(userData.gamesPlayed || 0);
        setQuizzesPassed(userData.quizzesPassed || 0);
        setStatCorrectNotes(userData.statCorrectNotes || 0);
        setStatIncorrectNotes(userData.statIncorrectNotes || 0);
        setStatCorrectNoteStreak(userData.statCorrectNoteStreak || 0);
        setStatMaxCorrectNoteStreak(userData.statMaxCorrectNoteStreak || 0);
        setStatWinStreak(userData.statWinStreak || 0);
        setStatMaxWinStreak(userData.statMaxWinStreak || 0);
        setHighScore(userData.highScore || 0);

        // other stats
      }
    }).catch(error => console.error("Error fetching user data:", error));
  }, [userId]);
  

  function incrementGamesPlayed() {
    if (!userId) return;
    const newCount = gamesPlayed + 1;
    setGamesPlayed(newCount);
    const userRef = doc(db, "users", userId);
    setDoc(userRef, { gamesPlayed: newCount }, { merge: true }).then(() => {
      checkAndAwardBadges();  // Check for badges after updating the game count
    });
  }
  

  function incrementQuizzesPassed() {
    if (!userId) return;
    const newCount = quizzesPassed + 1;
    setQuizzesPassed(newCount);
    const userRef = doc(db, "users", userId);
    setDoc(userRef, { quizzesPassed: newCount }, { merge: true });
  }

  function updateLevelStats(newStats) {
    setLevelStats(newStats);
    if (userId) {
      const userRef = doc(db, "users", userId);
      setDoc(userRef, { levelStats: newStats }, { merge: true });
    }
  }

  function incrementStatCorrectNotes() {
    if (!userId) return;
    const newCount = statCorrectNotes + 1;
    setStatCorrectNotes(newCount);
    const userRef = doc(db, "users", userId);
    setDoc(userRef, { statCorrectNotes: newCount }, { merge: true });
  }

  function incrementStatIncorrectNotes() {
    if (!userId) return;
    const newCount = statIncorrectNotes + 1;
    setStatIncorrectNotes(newCount);
    const userRef = doc(db, "users", userId);
    setDoc(userRef, { statIncorrectNotes: newCount }, { merge: true });
  }

  function incrementStatCorrectNoteStreak() {
    const newCount = statCorrectNoteStreak + 1;
    setStatCorrectNoteStreak(newCount);
    if (userId) {
      const userRef = doc(db, "users", userId);
      setDoc(userRef, { statCorrectNoteStreak: newCount }, { merge: true });
    }
  }
  
  function resetStatCorrectNoteStreak() {
    setStatCorrectNoteStreak(0);
    if (userId) {
      const userRef = doc(db, "users", userId);
      setDoc(userRef, { statCorrectNoteStreak: 0 }, { merge: true });
    }
  }
  

  function incrementStatMaxCorrectNoteStreak() {
    if (!userId) return;
    const newCount = statMaxCorrectNoteStreak + 1;
    setStatMaxCorrectNoteStreak(newCount);
    const userRef = doc(db, "users", userId);
    setDoc(userRef, { statMaxCorrectNoteStreak: newCount }, { merge: true });
  }

  function incrementStatWinStreak() {
    if (!userId) return;
    const newCount = statWinStreak + 1;
    setStatWinStreak(newCount);
    const userRef = doc(db, "users", userId);
    setDoc(userRef, { statWinStreak: newCount }, { merge: true });
  }

  function incrementStatMaxWinStreak() {
    if (!userId) return;
    const newCount = statMaxWinStreak + 1;
    setStatMaxWinStreak(newCount);
    const userRef = doc(db, "users", userId);
    setDoc(userRef, { statMaxWinStreak: newCount }, { merge: true });
  }
  

  function resetStatWinStreak() {
    if (!userId) return;
    setStatWinStreak(0); // Reset win streak to 0
    const userRef = doc(db, "users", userId);
    setDoc(userRef, { statWinStreak: 0 }, { merge: true }); 
  }

  function updateMaxUnlockedLevel(newLevel) {
    if (!userId) return;
    setMaxUnlockedLevel(newLevel);
    const userRef = doc(db, "users", userId);
    setDoc(userRef, { maxUnlockedLevel: newLevel }, { merge: true });
  }


  function updateStatMaxCorrectNoteStreak() {
    if (!userId) return;
    if (statCorrectNoteStreak > statMaxCorrectNoteStreak) {
      setStatMaxCorrectNoteStreak(statCorrectNoteStreak);
      const userRef = doc(db, "users", userId);
      setDoc(userRef, { statMaxCorrectNoteStreak: statCorrectNoteStreak }, { merge: true });
    }
  }
  

  function updateStatMaxWinStreak() {
    if (!userId) return;
    if (statWinStreak > statMaxWinStreak) {
      setStatMaxWinStreak(statWinStreak);
      const userRef = doc(db, "users", userId);
      setDoc(userRef, { statMaxWinStreak: statWinStreak }, { merge: true });
    }
  }

  function updateHighScore(newScore) {
    if (newScore > highScore) {
      setHighScore(newScore);
      if (userId) {
        const userRef = doc(db, "users", userId);
        setDoc(userRef, { highScore: newScore }, { merge: true });
      }
    }
  }
  
  //SHORT TERM > CHECKS OLD QUIZES FOR HIGH SCORE
  useEffect(() => {
    if (!userId) return;
  
    const quizzesRef = collection(db, `users/${userId}/quizzes`);
    getDocs(quizzesRef).then(querySnapshot => {
      const quizzes = querySnapshot.docs.map(doc => doc.data());  
      const highestScore = quizzes.reduce((highest, quiz) => {
        const correct = quiz.correctAnswers || 0;  // Default to 0 if undefined
        const incorrect = quiz.incorrectAnswers || 0;  // Default to 0 if undefined
        const totalAnswers = correct + incorrect;
        const score = totalAnswers > 0 ? ((correct / totalAnswers) * 100).toFixed(0) : 0;  // Check totalAnswers to avoid division by zero
        return Math.max(highest, score);
      }, 0);
  
      setHighScore(highestScore);  // Assuming you have a setHighScore function to update state
    }).catch(error => {
      console.error("Error fetching quizzes data:", error);
    });
  }, [userId, db]);
  
  //END SHORT TERM CHECK FOR OLD QUIZ HIGH SCORE

  
  //checking level stats for badge display:
  useEffect(() => {
    if (!userId) return;

    const userRef = doc(db, "users", userId);
    getDoc(userRef).then(docSnapshot => {
        if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            console.log('Current user data:', userData); // Add this to log the data
            setLevelStats(userData.levelStats || {});
            // other stats updates
        }
    }).catch(error => console.error("Error fetching user data:", error));
}, [userId]);
//end levelstats check



  // Add more utility functions for the new stats as needed.

  return (
    <StatsContext.Provider value={{
      gamesPlayed,
      incrementGamesPlayed,
      highScore,
      updateHighScore,
      quizzesPassed,
      incrementQuizzesPassed,
      statCorrectNotes,
      incrementStatCorrectNotes, 
      statIncorrectNotes,
      incrementStatIncorrectNotes,
      resetStatCorrectNoteStreak,
      statCorrectNoteStreak,
      incrementStatCorrectNoteStreak,
      statMaxCorrectNoteStreak,
      incrementStatMaxCorrectNoteStreak, 
      statWinStreak,
      incrementStatWinStreak, 
      statMaxWinStreak,
      resetStatWinStreak,
      incrementStatMaxWinStreak, 
      updateStatMaxWinStreak,
      updateStatMaxCorrectNoteStreak,
      levelStats,
      updateLevelStats,
      maxUnlockedLevel,
      updateMaxUnlockedLevel,
      deleteQuiz,
      quizzes,
    }}>
      {children}
    </StatsContext.Provider>
  );
};

export default StatsProvider;
