import React, { useState } from 'react';
import { Dialog, DialogContent, DialogActions, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import useLocalStorage from '../hooks/useLocalStorage';

const WelcomeModal = ({ isOpen, onClose }) => {
    const [pageIndex, setPageIndex] = useState(0);
    const [showAgain, setShowAgain] = useLocalStorage('showWelcomeModal', false); // Handles local storage internally
    const content = [
        "Welcome to PitchCraft! Here's how you get started...",
        "You will hear a music note, Your job is to play that note back. Once you get it right you can move on the next note.",
        "You will have 5 minutes to get as many notes correct as possible",
        "You can adjust the difficulty by selecting the level, mode, octaves, and instrument",
        "If you want to track your progress, create an account to store all of you quiz stats!"
    ];

    const handleCheckboxChange = (event) => {
        setShowAgain(event.target.checked);
    };

    const handleClose = () => {
        onClose(); 
    };

    const handleNext = () => {
        if (pageIndex < content.length - 1) {
            setPageIndex(pageIndex + 1);
        }
    };

    const handleBack = () => {
        if (pageIndex > 0) {
            setPageIndex(pageIndex - 1);
        }
    };

    return (
        <Dialog open={isOpen} onClose={handleClose}>
            <DialogContent>
                <Typography style={{ padding: '20px' }}>{content[pageIndex]}</Typography>
                <DialogActions>
                    {pageIndex > 0 && <Button variant="outlined" onClick={handleBack}>Back</Button>}
                    {pageIndex < content.length - 1 && <Button variant="contained" onClick={handleNext}>Next</Button>}
                    {pageIndex === content.length - 1 && (
                        <Button variant="contained" onClick={handleClose} color="primary">
                            Start Playing
                        </Button>
                    )}
                </DialogActions>
            </DialogContent>
            <DialogActions style={{ justifyContent: 'flex-start', padding: '8px 24px' }}>
                <FormControlLabel
                    control={<Checkbox checked={showAgain} onChange={handleCheckboxChange} />}
                    label={<Typography variant="body2" style={{ fontSize: '0.8rem' }}>Show this message next time I vist</Typography>}
                />
            </DialogActions>
        </Dialog>
    );
};

export default WelcomeModal;
