import React from 'react';
import { Grid, Typography, Paper, List, ListItem, ListItemText, Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';




const AboutPage = () => {
    return (
        <Grid container justifyContent="center" spacing={2} sx={{ padding: { xs: 2, sm: 0 } }}>
            <Grid item xs={12} sm={10} md={8} lg={6} xl={6}>
                <Paper elevation={3} style={{ padding: '24px' }}>
                    <Typography variant="h4" >
                        Welcome to Pitchcraft!
                    </Typography>
                    <Typography variant="h5" gutterBottom>The best ear trainer on the planet.</Typography>
                    <Divider>
                        <Chip label="How to play" size="small" color="primary" />
                    </Divider>
                    <List>
                        <ListItem>
                            <ListItemText
                                primary="Step 1: Find Your Level"
                                secondary={
                                    <Typography component="span" variant="body2" color="textPrimary">
                                        Pitchcraft has 11 levels. See which level you don't get 100%, that's the level to work on.
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Step 2: Find Your Note Triggers"
                                secondary={
                                    <>
                                        <Typography component="span" variant="body2" color="textPrimary">
                                            Assign a melody to each note, it should be a melody that you are familiar with. We'll have thourough list of melodies soon.  
                                        </Typography>

                                    </>
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText
                                primary="Step 3: Repetition"
                                secondary={
                                    <Typography component="span" variant="body2" color="textPrimary">
                                        I recommend not more than 5 minutes a day. This is like exercise for your brain and it will get tired.
                                        If you practiced 5 minutes a day for a month and you're stuck on the same level <Link href="mailto:pitchcraft432@gmail.com">email me</Link>.
                                    </Typography>
                                }
                            />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Have Fun!">
                                </ListItemText>
                        </ListItem>
                    </List>
                    
                </Paper>
            </Grid>
        </Grid>
    );
}

export default AboutPage;
