// chordUtils.js

const notes = [
  { name: 'C', midi: 60 },
  { name: 'C#', midi: 61 },
  { name: 'D', midi: 62 },
  { name: 'D#', midi: 63 },
  { name: 'E', midi: 64 },
  { name: 'F', midi: 65 },
  { name: 'F#', midi: 66 },
  { name: 'G', midi: 67 },
  { name: 'G#', midi: 68 },
  { name: 'A', midi: 69 },
  { name: 'A#', midi: 70 },
  { name: 'B', midi: 71 },
];

const noteNameToMidiNumber = (noteName) => {
  const regex = /^([A-G][b#]?)(-?\d+)$/;
  const match = noteName.match(regex);

  if (match) {
    const note = match[1];
    const octave = parseInt(match[2]);
    const noteMap = {
      'C': 0,
      'C#': 1,
      'Db': 1,
      'D': 2,
      'D#': 3,
      'Eb': 3,
      'E': 4,
      'F': 5,
      'F#': 6,
      'Gb': 6,
      'G': 7,
      'G#': 8,
      'Ab': 8,
      'A': 9,
      'A#': 10,
      'Bb': 10,
      'B': 11
    };

    return (octave + 1) * 12 + noteMap[note];
  }

  throw new Error('Invalid note name: ' + noteName);
};


export const generateRandomChord = (noteCount = 1, noteOptions = [], selectedOctave) => {
  const chordNotes = new Set();
  const usedNoteNames = new Set(); // Set to keep track of used note names without octave
  let octaves = (selectedOctave === "3,4") ? ["3", "4"] : [selectedOctave];

  // Limiting noteCount to the number of unique notes available
  const uniqueNoteCount = new Set(noteOptions).size * octaves.length;
  const adjustedNoteCount = Math.min(noteCount, uniqueNoteCount);

  while (chordNotes.size < adjustedNoteCount) {
    const randomNote = noteOptions[Math.floor(Math.random() * noteOptions.length)];
    const chosenOctave = octaves[Math.floor(Math.random() * octaves.length)];
    const noteName = randomNote + chosenOctave;

    // Check if the note name (without octave) is already used
    if (!usedNoteNames.has(randomNote)) {
      const midiNumber = noteNameToMidiNumber(noteName);
      chordNotes.add(midiNumber);
      usedNoteNames.add(randomNote); // Add the note name (without octave) to the set
    }
  }

  return { notes: Array.from(chordNotes) };
};
