import { db } from './firebase.utils';
import { collection, doc, setDoc, getDocs } from 'firebase/firestore';

export const saveQuizResult = async (userId, quizData) => {
  if (!userId || !quizData) return;

  const userQuizzesRef = collection(db, 'users', userId, 'quizzes');
  const quizDocRef = doc(userQuizzesRef); // Let Firestore generate the quiz document ID

  try {
    await setDoc(quizDocRef, {
      ...quizData,
      timestamp: new Date() // Add a timestamp if not already included
    });
    console.log("Quiz result saved successfully");
  } catch (error) {
    console.error("Error saving quiz result: ", error.message);
  }
};

export const getUserQuizStats = async (userId) => {
  if (!userId) return [];

  const quizzesRef = collection(db, 'users', userId, 'quizzes');
  try {
    const querySnapshot = await getDocs(quizzesRef);
    return querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error("Error fetching quiz stats: ", error.message);
    return [];
  }
};
