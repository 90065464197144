import React, { useState, useContext } from 'react';
import { Box, Tab, Tabs, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import badgeConfig from '../utils/badgeConfig';
import { StatsContext } from '../contexts/StatsContext';


const baseModes = ['default'];
const extendedModes = ['sequential', 'chord'];
const modeLabels = [{ mode: 'default', label: 'Default Mode' }];

extendedModes.forEach(mode => {
    Object.keys(badgeConfig.badgeThresholds[mode]).forEach(noteCount => {
        baseModes.push(`${mode} ${noteCount}`);
        modeLabels.push({ mode: `${mode} ${noteCount}`, label: `${mode.charAt(0).toUpperCase() + mode.slice(1)} - ${noteCount} Notes` });
    });
});


const BadgeDisplay = () => {
    const { quizzes } = useContext(StatsContext);
    const [tabIndex, setTabIndex] = useState(0);

    // Dynamically generate tab labels and modes
    const generateTabs = () => {
        const baseModes = ['default'];  // Initialize with default mode
        const modeLabels = [{ mode: 'default', label: 'Default Mode (1 Note)' }];

        const extendedModes = ['sequential', 'chord'];
        extendedModes.forEach(mode => {
            Object.keys(badgeConfig.badgeThresholds[mode]).forEach(noteCount => {
                const modeKey = `${mode} ${noteCount}`;
                baseModes.push(modeKey);
                modeLabels.push({ mode: modeKey, label: `${mode.charAt(0).toUpperCase() + mode.slice(1)} - ${noteCount} Notes` });
            });
        });

        return { baseModes, modeLabels };
    };

    const { baseModes, modeLabels } = generateTabs();

    const handleChangeTab = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    const calculateBadges = (modeWithCount) => {
        const [mode, noteCount] = modeWithCount.split(' ');
        console.log(`Calculating badges for mode: ${mode}, note count: ${noteCount}`);
    
        const levels = ["C,D", "C,D,E", "C,D,E,F", "C,D,E,F,G", "C,D,E,F,G,A", "C,D,E,F,G,A,B", "C,D,E,F,G,A,B,F#", "C,D,E,F,G,A,B,F#,C#", "C,D,E,F,G,A,B,F#,C#,G#", "C,D,E,F,G,A,B,F#,C#,G#,D#", "C,D,E,F,G,A,B,F#,C#,G#,D#,A#"];
        const badgesEarned = {};
    
        // Initialize badges for all levels
        levels.forEach(level => {
            badgesEarned[level] = {};
            badgeConfig.badgeLevels.forEach(badge => {
                badgesEarned[level][badge] = false;
            });
        });
    
        // Award badges based on quizzes
        quizzes.forEach(quiz => {
            const score = (quiz.correctAnswers / (quiz.correctAnswers + quiz.incorrectAnswers || 1)) * 100;
            const level = quiz.selectedNotes.join(",");
            const config = badgeConfig.getThresholds(quiz.mode, quiz.noteCount);
    
            if (config && quiz.mode === mode && (!noteCount || quiz.noteCount.toString() === noteCount)) {
                badgeConfig.badgeLevels.forEach((badge, index) => {
                    const scoreThreshold = config.scores[index];
                    const notesRequired = config.notesRequired[index];
                    if (score >= scoreThreshold && quiz.correctAnswers >= notesRequired) {
                        badgesEarned[level][badge] = true;
                        console.log(`Badge ${badge} earned at level ${level} for mode ${mode} and note count ${noteCount}`);
                    }
                });
            }
        });
    
        // Propagate badges to simpler levels
        for (let i = levels.length - 2; i >= 0; i--) { // Start from the second last to the first
            const currentLevel = levels[i];
            const higherLevel = levels[i + 1];
    
            badgeConfig.badgeLevels.forEach(badge => {
                if (badgesEarned[higherLevel][badge]) {
                    badgesEarned[currentLevel][badge] = true;
                    console.log(`Badge ${badge} propagated from ${higherLevel} to ${currentLevel}`);
                }
            });
        }
    
        return badgesEarned;
    };
    
    
    

    const badgesEarned = calculateBadges(baseModes[tabIndex]);

    const getTooltipText = (badge, index) => {
        const modeWithCount = baseModes[tabIndex];
        const [mode, noteCountString] = modeWithCount.split(' ');
        const noteCount = parseInt(noteCountString, 10);  // This might not be directly used as "notes required"
        const config = badgeConfig.getThresholds(mode, noteCount);
    
        if (!config || config.scores.length <= index) {
            return `Data unavailable for the ${badge} badge.`;
        }
    
        const scoreRequired = config.scores[index];
        const notesRequired = config.notesRequired[index];
        const description = badgeConfig.badgeDescriptions[badge].description;
    
        return `Get ${notesRequired} correct notes with a score of ${scoreRequired}% or better to earn the ${badge} badge.`;
    };
    
    
    


    return (
        <Box sx={{ width: '100%' }}>
            <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="Badge mode tabs"
            variant="scrollable"
            scrollButtons="auto">
                {modeLabels.map((tab, index) => (
                    <Tab key={index} label={tab.label} />
                ))}
            </Tabs>
            <Paper sx={{ overflowX: "auto" }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Level (Notes)</TableCell>
                            {badgeConfig.badgeLevels.map(level => (
                                <TableCell key={level} align="center">{level}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(badgesEarned).map(level => (
                            <TableRow key={level}>
                                <TableCell component="th" scope="row">{level}</TableCell>
                                {badgeConfig.badgeLevels.map((badge, index) => (
                                    <TableCell key={badge} align="center">
                                        <Tooltip 
                                        title={getTooltipText(badge, index)} 
                                        placement="top"
                                        interactive
                                        enterTouchDelay={0} // Reduces delay to show tooltip on touch devices
                                        >
                                            <span style={{ fontSize: '24px' }}>
                                                {badgesEarned[level][badge] ? badgeConfig.badgeDescriptions[badge].emoji : '⬜'}
                                            </span>
                                        </Tooltip>
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Box>
    );
};

export default BadgeDisplay;