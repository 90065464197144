import React, { createContext, useState, useMemo } from 'react';

export const DarkModeContext = createContext({
  darkMode: false,
  setDarkMode: () => {},
});

export const DarkModeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(window.matchMedia('(prefers-color-scheme: dark)').matches);

  const value = useMemo(() => ({
    darkMode,
    setDarkMode,
  }), [darkMode]);

  return (
    <DarkModeContext.Provider value={value}>
      {children}
    </DarkModeContext.Provider>
  );
};
