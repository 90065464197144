import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { DarkModeProvider } from "./contexts/DarkModeContext";
import { UserProvider } from "./contexts/user.context";
import { CssBaseline } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
      <CssBaseline>
        <DarkModeProvider>
            <UserProvider>
                <App />
            </UserProvider>
        </DarkModeProvider>
        </CssBaseline>
      </React.StrictMode>
);
