import SignInForm from "../../components/sign-in-form/sign-in-form.component";
import Container from '@mui/material/Container';



const SignIn = () => {
  return (
    <Container maxWidth="lg">
      <SignInForm />
    </Container>
  );
};

export default SignIn;
