import React, { useContext } from 'react';
import { Card, CardContent, Box, Grid, Typography, Button } from '@mui/material';
import { UserContext } from '../contexts/user.context';
import { signInWithGooglePopup } from '../utils/firebase/firebase.utils';

export default function StatsComponent({ correctAnswers, incorrectAnswers, noteStreak, winStreak }) {
    const { currentUser } = useContext(UserContext);

    const handleSignIn = async () => {
        await signInWithGooglePopup();
        // Additional logic after sign in (if needed)
    };

    return (
        <Grid container spacing={2} alignItems="stretch">
            {/* Correct Answers Card */}
            <Grid item xs={6}>
                <Card variant="outlined" style={{ width: '100%' }}>
                    <CardContent>
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <Typography variant="h6" component="div" align="center">
                                {correctAnswers}
                            </Typography>
                            <Typography variant="body2" align="center">
                                Correct
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>

            {/* Incorrect Answers Card */}
            <Grid item xs={6}>
                <Card variant="outlined">
                    <CardContent>
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                            <Typography variant="h6" component="div" align="center">
                                {incorrectAnswers}
                            </Typography>
                            <Typography variant="body2" align="center">
                                Incorrect
                            </Typography>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>

            {/* SignIn or Info Message */}
            <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item xs={12} sm={10} md={8}>
                        <Typography variant="body1" align="center" style={{ visibility: currentUser ? 'hidden' : 'visible' }}>
                            {!currentUser ? (
                                <a href="#" onClick={handleSignIn}>Create an account or log in to save all of your stats</a>
                            ) : (
                                "Placeholder for layout consistency"
                            )}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
