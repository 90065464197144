import React from 'react';
import { Typography, Box, Container, } from '@mui/material';

const Footer = () => {
  return (
<Box component="footer" py={2} mt={2} bgcolor="background.paper">
  <Container maxWidth="lg">
    <Typography variant="body2" color="text.secondary" align="center">
      &copy; {new Date().getFullYear()} 🎹 PitchCraft. All rights reserved.
    </Typography>
  </Container>
</Box>

  );
};

export default Footer;
