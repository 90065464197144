import React, { useState, useEffect, useRef, useContext, useCallback } from 'react';
import { Piano, MidiNumbers } from 'react-piano';
import 'react-piano/dist/styles.css';
import SoundfontProvider from './utils/SoundfontProvider';
import { StatsContext } from './contexts/StatsContext';
import { generateRandomChord } from './utils/chordUtils'
import StatsComponent from './components/StatsComponent'
import PianoConfig from './utils/PianoConfig';
import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Snackbar from '@mui/material/Snackbar';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormLabel from '@mui/material/FormLabel';
import Slider from '@mui/material/Slider';
import Confetti from 'react-dom-confetti';
import ResultsModal from './components/ResultsModal';
import { saveQuizResult } from './utils/firebase/firebaseservices';
import { auth } from './utils/firebase/firebase.utils';
import NoteStreakDisplay from './components/NoteStreakDisplay';
import WelcomeModal from './components/WelcomeModal';
import useLocalStorage from './hooks/useLocalStorage';

const ac = new AudioContext();
const audioContext = new (window.AudioContext || window.webkitAudioContext)();
const soundfontHostname = 'https://d1pzp51pvbm36p.cloudfront.net';

const PianoChordQuiz = () => {
  //Octave
  const [octave, setOctave] = useState(4); // Default octave is 4
  const handleOctaveChange = (event) => {
    const value = event.target.value;
    setOctave(value);

    const octaves = value.split(',').map(oct => parseInt(oct, 10));
    const firstOctave = Math.min(...octaves);
    const lastOctave = Math.max(...octaves);

    setNoteRange({
      first: MidiNumbers.fromNote(`c4`),
      last: MidiNumbers.fromNote(`b4`),
    });
  };

  const transposeToOctave = (note, octave) => {
    while (note > 11) {
      note -= 12;
    }
    return (octave - 1) * 12 + note;
  };

  const [noteRange, setNoteRange] = useState({
    first: MidiNumbers.fromNote('c4'),
    last: MidiNumbers.fromNote('b4'),
  });

  const createCustomKeyboardShortcuts = (octaves = []) => {
    const config = [
      { key: 'q', offset: 0 },
      { key: '2', offset: 1 },
      { key: 'w', offset: 2 },
      { key: '3', offset: 3 },
      { key: 'e', offset: 4 },
      { key: 'r', offset: 5 },
      { key: '5', offset: 6 },
      { key: 't', offset: 7 },
      { key: '6', offset: 8 },
      { key: 'y', offset: 9 },
      { key: '7', offset: 10 },
      { key: 'u', offset: 11 },
      { key: 'Q', offset: 0 },
      { key: 'W', offset: 2 },
      { key: 'E', offset: 4 },
      { key: 'R', offset: 5 },
      { key: 'T', offset: 7 },
      { key: 'Y', offset: 9 },
      { key: 'U', offset: 11 },
    ];

    // Assuming the octaves array is already sorted:
    const firstSelectedOctave = octaves[0];
    const baseNote = MidiNumbers.fromNote(`c${firstSelectedOctave}`);

    const shortcuts = config.map(({ key, offset }) => ({
      key,
      midiNumber: baseNote + offset,
    }));

    return shortcuts;
  };

  const [keyboardShortcuts, setKeyboardShortcuts] = useState(createCustomKeyboardShortcuts([4])); // Default octave is 4
  const [instrument, setInstrument] = useState(null);
  const [quizChord, setQuizChord] = useState(null);
  const [selectedNotes, setSelectedNotes] = useState([]);
  const [feedbackStyle, setFeedbackStyle] = useState("blank")
  const [playedNotes, setPlayedNotes] = useState([]);
  const [level, setLevel] = useState(21);
  const [isCheckingAnswer, setIsCheckingAnswer] = useState(false);
  const [currentLevel, setCurrentLevel] = useState(1);
  const [isAnswered, setIsAnswered] = useState(false);
  const [lastPlayedNote, setLastPlayedNote] = useState(null);
  const [isBetweenQuestions, setIsBetweenQuestions] = useState(false);
  const [noteInputLock, setNoteInputLock] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [timeLeft, setTimeLeft] = useState(0);
  const [correctAnswers, setCorrectAnswers] = useState(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState(0);
  const [quizRunning, setQuizRunning] = useState(false);
  const [quizStarted, setQuizStarted] = useState(false);
  const timerRef = useRef(null);
  const [feedback, setFeedback] = useState([]);
  const [isSequential, setIsSequential] = useState(false);  // State for the sequential toggle
  const [isChordMode, setIsChordMode] = useState(false);  // State for the chord mode toggle
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [resultsModalOpen, setResultsModalOpen] = useState(false);
  const [resultsData, setResultsData] = useState({
    correctAnswers: 0,
    incorrectAnswers: 0,
    selectedNotes: [],
  });

  const [alertSeverity, setAlertSeverity] = useState("success"); // or "error" for failed attempts
  const [selectedInstrument, setSelectedInstrument] = useState('acoustic_grand_piano');
  const [activeAudioNodes, setActiveAudioNodes] = useState({});
  const [lastPlayedChord, setLastPlayedChord] = useState([]);
  const [lastPlayedNode, setLastPlayedNode] = useState(null);

  //Welcome Modal 
  const [isModalOpen, setIsModalOpen] = useLocalStorage('showWelcomeModal', true);

    useEffect(() => {
        const showWelcome = JSON.parse(localStorage.getItem('showWelcomeModal'));
        // If there's nothing in local storage or if showWelcome is true, show the modal
        if (showWelcome === null || showWelcome) {
            setIsModalOpen(true);
        }
    }, []);

  //Mobile labels
  const isMobile = window.innerWidth <= 800;

  //AutoPlay Notes
  const [autoPlay, setAutoPlay] = useState(false);

  // Initialize state based on device type
  const [showLabels, setShowLabels] = useState(false);
  const [showNoteNames, setShowNoteNames] = useState(true); // Default to true to show note names

    //initialize streaks to true, meaning player will see streaks by default
  const [showStreak, setShowStreak] = useLocalStorage('showStreak', true);

  useEffect(() => {
    const showingStreaks = JSON.parse(localStorage.getItem('showStreaks'));
      //guarantee that streak is showing by default
      if (showingStreaks === null || showingStreaks) {
        setShowStreak(true);
      } else {
        //handles false in localstorage from previous session
        setShowStreak(showingStreaks);
      }
  }, []);
  
  const handleShowStreakChange = (prevShowStreak) => {
    setShowStreak(!prevShowStreak);
  };


  // This useEffect will handle window resize events and adjust the showLabels state
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 800 && showLabels) {
        setShowLabels(false);
      }
      else if (window.innerWidth > 800 && !showLabels) {
        setShowLabels(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [showLabels]);


  const onChangeInstrument = (event) => {
    setSelectedInstrument(event.target.value);
  };

  const getDisplayLevel = (currentLevel) => {
    return currentLevel % 2 === 0 ? currentLevel - 1 : currentLevel;
  };

  //MUST SET FEEDBACK TO ARRAY!!
  useEffect(() => {
    if (quizChord) {
      setFeedback(Array(quizChord.notes.length).fill("❔ What note?"));
    }
  }, [quizChord]);

  //Confetti Configuration //
  const config = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
  };
  const [confetti, setConfetti] = React.useState(false);

  const handleConfetti = () => {
    setConfetti(true);
  }
  //Stat Variables //

  const {
    incrementGamesPlayed, incrementQuizzesPassed, incrementStatCorrectNotes, incrementStatCorrectNoteStreak, incrementStatIncorrectNotes, incrementStatMaxCorrectNoteStreak, updateMaxCorrectNoteStreak,
    statCorrectNotes, setStatCorrectNotes, resetStatWinStreak, resetStatCorrectNoteStreak,
    statIncorrectNotes, setStatIncorrectNotes,
    statCorrectNoteStreak, setStatCorrectNoteStreak,
    statMaxCorrectNoteStreak, setStatMaxCorrectNoteStreak, incrementStatWinStreak, incrementStatMaxWinStreak,
    statWinStreak, setStatWinStreak,
    statMaxWinStreak, setStatMaxWinStreak, levelStats, updateStatMaxWinStreak, updateStatMaxCorrectNoteStreak,
    updateLevelStats, highScore, updateHighScore
    // include other stats as needed...
  } = useContext(StatsContext);

  // Start Quiz
  const startQuiz = () => {
    console.log('noteCount:', noteCount);

    if (quizRunning) {
      console.log("Quiz is already running");
      return;
    }
    const now = Date.now();
    setStartTime(now);
    setEndTime(now + timeLength);
    setCorrectAnswers(0);
    setIncorrectAnswers(0);
    resetStatCorrectNoteStreak();
    setQuizRunning(true);
    setQuizStarted(true);
    setFeedbackStyle('blank');
    setFeedback(["❔ What note was that?"]);
    setConfetti(false);
    setPlayedNotes([]);
    setNoteInputLock(false);
    setQuizEnded(false); // Ensure we're resetting this to allow for a fresh start
    console.log("quiz started");
  };

  useEffect(() => {
    if (startTime && endTime) {
      updateTimeLeft();
    }
  }, [startTime, endTime]);

  const updateTimeLeft = () => {
    timerRef.current = setInterval(() => {  // change this line
      const now = Date.now();  // changed from new Date() to Date.now()
      const timeLeft = Math.round((endTime - now) / 1000);

      if (timeLeft <= 0) {
        clearInterval(timerRef.current);  // change this line
        setTimeLeft(0);
        setQuizRunning(false);
        endQuiz();
      } else {
        setTimeLeft(timeLeft);
      }
    }, 1000);
  };

  function formatTime(timeInSeconds) {
    let minutes = Math.floor(timeInSeconds / 60);
    let seconds = timeInSeconds % 60;

    if (minutes < 10) minutes = "0" + minutes;
    if (seconds < 10) seconds = "0" + seconds;

    return `${minutes}:${seconds}`;
  }



  useEffect(() => {
    if (!quizRunning && quizStarted) {
      if (correctAnswers >= 20) { //adjust number of correct for pass/fail
        //alert("You passed!");
        incrementGamesPlayed();
        incrementQuizzesPassed();
        setAlertMessage("You passed!");
        setAlertSeverity("success");
        setOpenSnackbar(true);
        setConfetti(true);
        updateStatMaxWinStreak();
        const oldTimesCompleted = levelStats[currentLevel]?.timesCompleted || 0;
        const oldAverageScore = levelStats[currentLevel]?.averageScore || 0;
        const newTimesCompleted = oldTimesCompleted + 1;
        const newAverageScore = ((oldAverageScore * oldTimesCompleted) + correctAnswers) / newTimesCompleted;
        updateLevelStats(currentLevel, { timesCompleted: newTimesCompleted, averageScore: newAverageScore });
        const score = (correctAnswers / (correctAnswers + incorrectAnswers)) * 100;

      } else {
        //alert("You didn't pass. Better luck next time!");
        setAlertMessage("You didn't pass. Better luck next time!");
        incrementGamesPlayed();
        setAlertSeverity("error");
        setOpenSnackbar(true);
        resetStatWinStreak(); // Reset win streak to 0
        const oldTimesCompleted = levelStats[currentLevel]?.timesCompleted || 0;
        const oldAverageScore = levelStats[currentLevel]?.averageScore || 0;
        const newTimesCompleted = oldTimesCompleted + 1;
        const newAverageScore = ((oldAverageScore * oldTimesCompleted) + correctAnswers) / newTimesCompleted;

        updateLevelStats(currentLevel, { timesCompleted: newTimesCompleted, averageScore: newAverageScore });
      }
      const score = (correctAnswers / (correctAnswers + incorrectAnswers)) * 100;

      // Check if the current score is greater than the high score and update if so
      if (score > highScore) {
        updateHighScore(score);
      }
    }
  }, [quizRunning, quizStarted, correctAnswers, incorrectAnswers, highScore,]);

  const getNoteOptions = (level) => {
    switch (level) {
      case 1:
        return ['C', 'D'];
      case 2:
        return ['C', 'D'];
      case 3:
        return ['C', 'D', 'E'];
      case 4:
        return ['C', 'D', 'E'];
      case 5:
        return ['C', 'D', 'E', 'F'];
      case 6:
        return ['C', 'D', 'E', 'F'];
      case 7:
        return ['C', 'D', 'E', 'F', 'G'];
      case 8:
        return ['C', 'D', 'E', 'F', 'G'];
      case 9:
        return ['C', 'D', 'E', 'F', 'G', 'A'];
      case 10:
        return ['C', 'D', 'E', 'F', 'G', 'A'];
      case 11:
        return ['C', 'D', 'E', 'F', 'G', 'A', 'B'];
      case 12:
        return ['C', 'D', 'E', 'F', 'G', 'A', 'B'];
      case 13:
        return ['C', 'D', 'E', 'F', 'G', 'A', 'B', 'F#'];
      case 14:
        return ['C', 'D', 'E', 'F', 'G', 'A', 'B', 'F#'];
      case 15:
        return ['C', 'D', 'E', 'F', 'G', 'A', 'B', 'F#', 'C#'];
      case 16:
        return ['C', 'D', 'E', 'F', 'G', 'A', 'B', 'F#', 'C#'];
      case 17:
        return ['C', 'D', 'E', 'F', 'G', 'A', 'B', 'F#', 'C#', 'G#'];
      case 18:
        return ['C', 'D', 'E', 'F', 'G', 'A', 'B', 'F#', 'C#', 'G#'];
      case 19:
        return ['C', 'D', 'E', 'F', 'G', 'A', 'B', 'F#', 'C#', 'G#', 'D#'];
      case 20:
        return ['C', 'D', 'E', 'F', 'G', 'A', 'B', 'F#', 'C#', 'G#', 'D#'];
      case 21:
        return ['C', 'D', 'E', 'F', 'G', 'A', 'B', 'F#', 'C#', 'G#', 'D#', 'A#'];
      case 22:
        return ['C', 'D', 'E', 'F', 'G', 'A', 'B', 'F#', 'C#', 'G#', 'D#', 'A#'];
      case 23:
        return ['C', 'D', 'E', 'F', 'G', 'A', 'B', 'F#', 'C#', 'G#', 'D#', 'A#'];
      case 24:
        return ['C', 'D', 'E', 'F', 'G', 'A', 'B', 'F#', 'C#', 'G#', 'D#', 'A#'];
      case 25:
        return ['C', 'D', 'E', 'F', 'G', 'A', 'B', 'F#', 'C#', 'G#', 'D#', 'A#'];
      default:
        return ['C', 'D'];
    }
  };

  const [noteCount, setNoteCount] = useState(1);
  const [maxNotes, setMaxNotes] = useState(5);
  const levels = [
    { level: 1, },
    { level: 2, },
    { level: 3, },
    { level: 4, },
    { level: 5, },
    { level: 6, },
    { level: 7, },
    { level: 8, },
    { level: 9, },
    { level: 10, },
    { level: 11, },
    { level: 12, },
    { level: 13, },
    { level: 14, },
    { level: 15, },
    { level: 16, },
    { level: 17, },
    { level: 18, },
    { level: 19, },
    { level: 20, },
    { level: 21, },
    { level: 22, },
    { level: 23, },
    { level: 24, },
    { level: 25, },
    { level: 26, },
    { level: 27, },
    { level: 28, },
    // Add more levels if needed
  ];

  const timeLength = 300000; //time in milliseconds 300000 = 5 min
  useEffect(() => {
    if (instrument && quizRunning) { // added quizRunning condition here
      console.log("Selected octave: ", octave);  // Log selectedOctave value
      console.log("Note options: ", getNoteOptions(level));  // Log noteOptions value
      const newChord = generateRandomChord(noteCount, getNoteOptions(level), octave);
      console.log("New chord: ", newChord);
      setQuizChord(newChord);
      playChord(instrument, newChord.notes, false);
      const noteNames = newChord.notes.map(midiNumberToNoteName);
      const keyboardButtons = newChord.notes.map((midiNumber) => {
        const shortcut = keyboardShortcuts.find((s) => s.midiNumber === midiNumber);
        return shortcut ? shortcut.key : 'N/A';
      }, [instrument, level]);

      console.log(
        'Correct notes:',
        newChord.notes,
        'Note names:',
        noteNames,
        'Keyboard buttons:',
        keyboardButtons
      );
    }
  }, [instrument, quizRunning, noteCount]); // added quizRunning to the dependency array

  const [currentQuestionAnswered, setCurrentQuestionAnswered] = useState(false);

  // Remember to reset currentQuestionAnswered to false whenever a new question is presented
  // For example:
  useEffect(() => {
    setCurrentQuestionAnswered(false);
  }, [quizChord]);

  const getRandomMidiNumber = () => {
    // MIDI numbers for C3 and C4. 
    const midiC3 = MidiNumbers.fromNote('C3');
    const midiC4 = MidiNumbers.fromNote('C4');

    if (octave === '3,4') {
      const randomOctave = Math.random() < 0.5 ? 3 : 4;

      if (randomOctave === 3) {
        // Randomly selecting a MIDI number from the C3-B3 range.
        return Math.floor(Math.random() * 12) + midiC3;
      } else {
        // Randomly selecting a MIDI number from the C4-B4 range.
        return Math.floor(Math.random() * 12) + midiC4;
      }
    } else {
      const selectedMidiStart = octave === '3' ? midiC3 : midiC4;
      // Randomly selecting a MIDI number from the selected octave.
      return Math.floor(Math.random() * 12) + selectedMidiStart;
    }
  };

  const getNoteNameWithoutOctave = (midiNumber) => {
    const noteNames = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
    return noteNames[midiNumber % 12];
  };

  const midiNumberToNoteName = (midiNumber) => {
    const octave = Math.floor(midiNumber / 12) - 1;
    const noteNames = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
    const noteName = noteNames[midiNumber % 12];
    return `${noteName}${octave}`;
  };

  const stopNote = (midiNumber) => {
    if (!activeAudioNodes[midiNumber]) {
      return;
    }
    const audioNode = activeAudioNodes[midiNumber];
    audioNode.stop();

    setActiveAudioNodes(prevNodes => ({
      ...prevNodes,
      [midiNumber]: null
    }));
  };

  const playChordWithDelay = (instrument, note, delay, isUserInput) => {
    return new Promise(resolve => {
      if (isSequential && lastPlayedNote !== null) {
        // Only stop in sequential mode.
        stopNote(lastPlayedNote);
      }

      // Add a tiny buffer to the duration (e.g., 0.95) to ensure a small gap.
      const duration = delay * 0.95 / 1000;  // Convert delay from ms to seconds for the SoundFont player.
      instrument.play(note, ac.currentTime, { duration });

      if (isUserInput) {
        onPlayNoteInput(note);
      }

      setLastPlayedNote(note);

      setTimeout(resolve, delay);
    });
  };

  const playChord = async (instrument, chordNotes, isUserInput) => {
    try {
      if (isChordMode) {
        chordNotes.forEach(note => {
          instrument.play(note, ac.currentTime, { duration: 0.75 });
          if (isUserInput) onPlayNoteInput(note);
        });
      } else {
        for (let i = 0; i < chordNotes.length; i++) {
          await playChordWithDelay(instrument, chordNotes[i], 500, isUserInput);
        }
      }
    } catch (error) {
      console.error('Error occurred in playChord: ', error);
    }
  };

  const onPlayNoteInput = (midiNumber) => {
    // If the quiz hasn't started yet, ignore played notes
    if (!quizStarted) {
      return;
    }
    // If input is currently locked, ignore played notes
    if (noteInputLock) {
      return;
    }

    setIsCheckingAnswer(true);

    setPlayedNotes((prevPlayedNotes) => {
      if (!prevPlayedNotes.includes(midiNumber)) {
        return [...prevPlayedNotes, midiNumber];
      } else {
        return prevPlayedNotes;
      }
    });
  };

  //Streaks 
  function handleMaxWinStreak() {
    if (statMaxWinStreak <= statWinStreak) {
      setStatMaxWinStreak(statWinStreak + 1)
    }
  }


  // state to indicate the start of the reset
  const [isResetStart, setIsResetStart] = useState(false);
  const handleReset = () => {
    // Ensure an instrument is selected before attempting to reset
    if (instrument) {
      setNoteInputLock(true); // Prevent new notes from being played during reset
      console.log("Reset started");

      setIsResetStart(true); // Indicate the start of a reset operation

      // Generate a new chord or note for the next question based on the current level and selected octaves
      const newChord = generateRandomChord(noteCount, getNoteOptions(level), octave);
      console.log("New chord: ", newChord);

      setQuizChord(newChord); // Set the new quiz chord or note
      setFeedback(["❔ What note was that?"]); // Reset feedback for the new question
      setFeedbackStyle("blank"); // Reset any feedback styling
      setCurrentQuestionMissed(false); // Reset the flag indicating a question has been missed
      setCurrentSequenceMissed(false); // Reset for sequence mode, ensuring it's ready for the next sequence
      // If you have a separate handling for sequences, ensure to reset it here as well
      // Ensure this state is declared and managed correctly elsewhere in your component
      // setCurrentSequenceMissed(false); // Ready for the next sequence

      setIsAnswered(false); // Reset the flag indicating whether the current question has been answered

      // Prepare for displaying the correct notes and keyboard shortcuts for the new chord
      const noteNames = newChord.notes.map(midiNumberToNoteName);
      const keyboardButtons = newChord.notes.map(midiNumber => {
        const shortcut = keyboardShortcuts.find(s => s.midiNumber === midiNumber);
        return shortcut ? shortcut.key : 'N/A';
      });

      setPlayedNotes([]); // Clear any notes that have been played

      // Log information about the new question for debugging
      console.log('Correct notes:', newChord.notes, 'Note names:', noteNames, 'Keyboard buttons:', keyboardButtons);

      // Play the new chord to start the next question
      playChord(instrument, newChord.notes, false);

      setTimeout(() => {
        setNoteInputLock(false); // Allow new notes to be played
        setIsResetStart(false); // Indicate the reset operation is complete
        console.log("Reset ended");
      }, 100); // Brief delay to ensure the reset transitions smoothly
    }
  };

  //Check user input and answers
  // Function to check answers in Default Mode
  const [currentQuestionMissed, setCurrentQuestionMissed] = useState(false);

  const checkDefaultModeAnswers = () => {
    console.log('Incorrect answer:', incorrectAnswers);
    // Early exit if there's no quiz chord or no played notes
    if (!quizChord || playedNotes.length === 0) return; // Early exit conditions

    // Extract the note names without octaves for comparison
    const correctNoteName = getNoteNameWithoutOctave(quizChord.notes[0]);
    const playedNoteName = getNoteNameWithoutOctave(playedNotes.at(-1)); // Check the last played note  

    if (correctNoteName === playedNoteName) {
      // Correct answer
      handleCorrectAnswerDefaultMode();
    } else {
      // Incorrect answer - allow retry without blocking correct answers
      handleIncorrectAnswerDefaultMode();
    }
  };

  // Handling correct answers in Default Mode
  const handleCorrectAnswerDefaultMode = () => {
    setFeedback(["✅ Correct!"]);
    setFeedbackStyle('correct');
    setCorrectAnswers(correctAnswers + 1);
    incrementStatCorrectNotes();
    incrementStatCorrectNoteStreak();
    updateStatMaxCorrectNoteStreak();
    setIsAnswered(true);
    setNoteInputLock(true); // Prevent further input for this question
    setPlayedNotes([]); // Clear played notes if moving to the next question automatically

    // Autoplay the next note if the feature is enabled
    if (autoPlay) {
      setTimeout(() => {
        handleReset(); // Proceed to the next question after a delay
      }, 200); // Adjust delay as needed
    }
  };


  // Handling incorrect answers in Default Mode - refined to allow correct subsequent answers
  const handleIncorrectAnswerDefaultMode = () => {
    setFeedback(["❌ Incorrect"]);
    setFeedbackStyle('incorrect');


    // Increment incorrect answers only if it's the first incorrect attempt for this question
    if (!currentQuestionMissed) {
      setIncorrectAnswers(prev => prev + 1); // Safely increment based on previous state
      incrementStatIncorrectNotes();
      resetStatCorrectNoteStreak();
      setCurrentQuestionMissed(true); // Flag the question as having an incorrect attempt
    }
    // Do not clear playedNotes to allow review of what was played
    // Do not lock input to allow further attempts
  };


  // Function to handle correct answers in Chord Mode
  const handleCorrectAnswerChordMode = (playedNoteNamesSet) => {
    // Update feedback for each note
    let feedbackTemp = quizChord.notes.map(note => {
      const noteName = getNoteNameWithoutOctave(note);
      return playedNoteNamesSet.has(noteName) ? `✅ Correct! ${noteName}` : `❔ Waiting...`;

    });

    setFeedback(feedbackTemp);

    // Check if all notes in the chord have been correctly played
    if (playedNoteNamesSet.size === quizChord.notes.length) {
      setCorrectAnswers(correctAnswers + 1);
      incrementStatCorrectNotes();
      incrementStatCorrectNoteStreak();
      updateStatMaxCorrectNoteStreak();
      setIsAnswered(true);
      setNoteInputLock(true);
      setPlayedNotes([]);
      setFeedbackStyle('correct');

      if (autoPlay) {
        setTimeout(handleReset, 500); // Auto-play the next question
      }
    }
  };

  // Function to handle incorrect answers in Chord Mode
  const handleIncorrectAnswerChordMode = () => {
    // Update feedback based on the mode
    if (quizChord.notes.length === 1) {
      setFeedback(["❌ Incorrect"]);
      setFeedbackStyle('incorrect');
    } else {
      // For multiple notes, indicate "Incorrect" for all notes if the first wrong note is hit
      setFeedback(Array(quizChord.notes.length).fill("❌ Incorrect"));
      setFeedbackStyle('incorrect');

    }

    // Only increment counters and set feedback style if this is the first incorrect attempt
    if (!currentQuestionMissed) {
      setIncorrectAnswers(incorrectAnswers + 1);
      incrementStatIncorrectNotes();
      resetStatCorrectNoteStreak();
      setCurrentQuestionMissed(true); // Mark the question as missed
    }

    setPlayedNotes([]);
    setFeedbackStyle('incorrect');
    setHasIncorrectAnswer(true);
  };



  const handleCorrectAnswerSequentialMode = () => {
    // Update feedback for each note
    let feedbackTemp = quizChord.notes.map((note, index) => {
      const correctNoteName = getNoteNameWithoutOctave(note);
      if (index < playedNotes.length) {
        const playedNoteName = getNoteNameWithoutOctave(playedNotes[index]);
        return playedNoteName === correctNoteName ? `✅ Correct! ${playedNoteName}` : `❔ Waiting...`;
      } else {
        return `❔ Waiting...`;
      }
    });

    setFeedback(feedbackTemp);

    // Check if all notes in the sequence have been correctly played
    if (playedNotes.length === quizChord.notes.length && feedbackTemp.every(f => f.startsWith("✅"))) {
      incrementStatCorrectNotes();
      incrementStatCorrectNoteStreak();
      updateStatMaxCorrectNoteStreak();
      setCorrectAnswers(correctAnswers + 1);
      setCurrentQuestionAnswered(true);
      setIsAnswered(true);
      setNoteInputLock(true);
      setPlayedNotes([]);
      setFeedbackStyle('correct');

      if (autoPlay) {
        setTimeout(handleReset, 600); // Auto-play the next question
      }
    }
  };

  const [currentSequenceMissed, setCurrentSequenceMissed] = useState(false);
  const handleIncorrectAnswerSequentialMode = () => {
    // Only increment incorrect answers and stats if this is the first miss in the current sequence
    if (!currentSequenceMissed) {
      incrementStatIncorrectNotes();
      setIncorrectAnswers(prev => prev + 1); // Safely increment based on previous state
      setCurrentSequenceMissed(true); // Mark that an incorrect answer has been recorded for this sequence
      setHasIncorrectAnswer(true);
      resetStatCorrectNoteStreak();
    }

    // Always clear played notes and update feedback regardless of whether it was the first miss
    setPlayedNotes([]);
    setFeedback(Array(quizChord.notes.length).fill("❔ Waiting..."));
    // Do not reset setCurrentSequenceMissed here; it should only be reset when a new sequence starts or the quiz resets
  };

  // Initialize a new state variable
  const [hasIncorrectAnswer, setHasIncorrectAnswer] = useState(false);

  const checkChordModeAnswers = () => {
    let playedNoteNamesSet = new Set(playedNotes.map(getNoteNameWithoutOctave));
    let correctNoteNamesSet = new Set(quizChord.notes.map(getNoteNameWithoutOctave));
    let feedbackTemp = [];

    // Track if any note is incorrect
    let incorrectNotePlayed = false;

    quizChord.notes.forEach(note => {
      const noteName = getNoteNameWithoutOctave(note);
      if (playedNoteNamesSet.has(noteName)) {
        // Note was played correctly
        feedbackTemp.push(`✅ Correct! ${noteName}`);
      } else {
        // Note has not been played yet
        feedbackTemp.push(`❔ Waiting...`);
      }
    });

    // Check if any played note is not in the correct notes set
    playedNotes.forEach(playedNote => {
      const playedNoteName = getNoteNameWithoutOctave(playedNote);
      if (!correctNoteNamesSet.has(playedNoteName)) {
        incorrectNotePlayed = true;
      }
    });

    // Update feedback based on the notes played so far
    setFeedback(feedbackTemp);

    // Handle incorrect notes immediately
    if (incorrectNotePlayed) {
      handleIncorrectAnswerChordMode();
    } else if (playedNoteNamesSet.size === quizChord.notes.length) {
      // If the number of unique played notes matches the chord and no incorrect notes, treat as correct
      handleCorrectAnswerChordMode(playedNoteNamesSet);
    }
  };



  const checkSequentialModeAnswers = () => {
    const correctNoteNames = quizChord.notes.map(midi => getNoteNameWithoutOctave(midi));
    const playedNoteNames = playedNotes.map(midi => getNoteNameWithoutOctave(midi));
    let incorrectNotePlayed = false;

    // Update feedback for each note played so far
    let feedbackTemp = quizChord.notes.map((note, index) => {
      const correctNoteName = getNoteNameWithoutOctave(note);
      if (index < playedNotes.length) {
        const playedNoteName = getNoteNameWithoutOctave(playedNotes[index]);
        if (playedNoteName !== correctNoteName) {
          incorrectNotePlayed = true;
          return `❌ Incorrect: ${playedNoteName}`;
        }
        return `✅ Correct! ${playedNoteName}`;
      } else {
        return `❔ Waiting...`;
      }
    });

    setFeedback(feedbackTemp);

    if (incorrectNotePlayed) {
      handleIncorrectAnswerSequentialMode();
    } else if (!incorrectNotePlayed && playedNotes.length === quizChord.notes.length) {
      // If all notes are played and are correct
      handleCorrectAnswerSequentialMode();
    }
  };


  useEffect(() => {
    // Early exit conditions
    if (!quizChord || isResetStart || currentQuestionAnswered || playedNotes.length === 0) {
      return;
    }

    setIsCheckingAnswer(true);

    // Determine the mode based on the number of notes and call the appropriate checking function
    if (quizChord.notes.length === 1) {
      checkDefaultModeAnswers();
    } else if (isChordMode) {
      checkChordModeAnswers();
    } else {
      checkSequentialModeAnswers();
    }

    setIsCheckingAnswer(false);
  }, [quizChord, isResetStart, currentQuestionAnswered, playedNotes, isChordMode]);


  const handleLevelChange = (event) => {
    const newLevel = parseInt(event.target.value, 10);
    setLevel(newLevel);
    console.log("Level set to:", newLevel);
  };

  const handleSequentialChange = (event) => {
    const newIsSequential = event.target.checked;
    setIsSequential(newIsSequential);

    //disable chord mode
    if (isChordMode === true) {
      setIsChordMode(false);
    }
    // Update the level accordingly
    setLevel(prevLevel => {
      // If the previous level was even (i.e., sequential), subtract 1
      // If the previous level was odd, add 1
      return prevLevel % 2 === 0 ? prevLevel - 1 : prevLevel + 1;
    });
  };

  const handleChordModeChange = (event) => {
    const newIsChordMode = event.target.checked;
    setIsChordMode(newIsChordMode);

    if (isSequential === true) {
      setIsSequential(false);
    }
    // Update the level accordingly
    setLevel(prevLevel => {
      // If the previous level was even (i.e., sequential), subtract 1
      // If the previous level was odd, add 1
      return prevLevel % 2 === 0 ? prevLevel - 1 : prevLevel + 1;
    });
  };
  const [mode, setMode] = useState("default");

  //new mode change

  const handleModeChange = (event) => {
    const newMode = event.target.value;
    console.log("Selected mode:", newMode);
    setMode(newMode); // Update the mode state
    const isSequentialMode = newMode === "sequential";
    const isChordMode = newMode === "chord";
    setIsSequential(isSequentialMode);
    setIsChordMode(isChordMode);
    console.log("isSequentialMode:", isSequentialMode); // Corrected log statement
    console.log("isChordMode:", isChordMode); // Corrected log statement
    console.log("level:", level);

    // Update the level based on the selected mode
    setLevel(prevLevel => {
      if (isSequentialMode || isChordMode) {
        // If enabling either mode, ensure level is odd
        return prevLevel % 2 === 0 ? prevLevel - 1 : prevLevel;
      } else {
        // If disabling both modes, ensure level is even
        return prevLevel % 2 === 0 ? prevLevel : prevLevel + 1;
      }
    });
  };

  //Keyboard shortcuts
  const replayNote = useCallback(() => {
    if (quizChord && quizChord.notes) {
      playChord(instrument, quizChord.notes, false);
    }
  }, [quizChord, instrument]);

  const nextNote = useCallback(() => {
    handleReset();
    console.log('next note should play')
  }, [handleReset]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      // if the space bar is pressed, replay the current note
      if (event.code === 'Space') {
        replayNote();
      }
      // if the enter key is pressed, go to the next note only if the current note has been answered correctly and autoPlay is off
      else if ((event.code === "Enter" || event.code === "NumpadEnter") && isAnswered && !autoPlay) {
        nextNote();
      }
    };

    // Add event listeners when the component mounts
    document.addEventListener('keydown', handleKeyPress);

    // Clean up the event listeners when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [replayNote, nextNote, isAnswered, autoPlay]);



  const onStopNoteInput = (midiNumber) => {
    setSelectedNotes((prevSelectedNotes) =>
      prevSelectedNotes.filter((note) => note !== midiNumber)
    );
  };
  ///END Keyboard Shortcutss

  // Number of notes:
  function updateNoteCount(event, value) {
    setNoteCount(value);
  }
  const getMaxNotesForLevel = (level) => {
    const noteOptions = getNoteOptions(level); // Assuming getNoteOptions returns an array of note strings
    const uniqueNoteCount = new Set(noteOptions).size;
    return Math.min(5, uniqueNoteCount); // Maximum of 5 notes or the number of unique notes
  };

  // useEffect for handling slider value adjustments based on level and mode changes
  useEffect(() => {
    let maxNotesForMode;

    if (mode === 'chord') {
      maxNotesForMode = 5; // Max notes for chord mode
    } else if (mode === 'sequential') {
      maxNotesForMode = 12; // Max notes for sequential mode
    } else {
      maxNotesForMode = 1; // Default mode should not have slider, so setting it to 1
    }

    const maxNotesForLevel = getMaxNotesForLevel(level);
    const maxNotes = Math.min(maxNotesForMode, maxNotesForLevel);

    setMaxNotes(maxNotes);
    setNoteCount(prevNoteCount => Math.min(prevNoteCount, maxNotes)); // Adjust noteCount if it exceeds the new max
  }, [level, mode]);

  //Time Stamp Code 
  // Utility function to format the date and time
  function formatTimestamp(date) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true };
    return new Intl.DateTimeFormat('en-US', options).format(date).replace(',', ' -').toLowerCase();
  }

  //END TIME STAMP

  //End Quiz Start
  const [quizEnded, setQuizEnded] = useState(false);

  // End Quiz
  const endQuiz = () => {
    console.log("Correct answers: ", correctAnswers);
    console.log("Time left: ", timeLeft);

    setQuizRunning(false);
    setTimeLeft(0);
    setEndTime(0);
    clearInterval(timerRef.current);
    setQuizEnded(true);
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null; // Resetting the ref
    }

  };

  useEffect(() => {
    if (quizEnded) {
      const currentTime = new Date();
      const timestamp = formatTimestamp(currentTime); // Ensure formatTimestamp is accessible
      setResultsData({
        correctAnswers,
        incorrectAnswers,
        // Make sure this logic correctly reflects how `octave` is stored and used
        selectedOctaves: Array.isArray(octave) ? octave : [octave],
        selectedNotes: getNoteOptions(level),
        mode: mode,
        timestamp: timestamp, // Add the timestamp to the results data
      });
      setResultsModalOpen(true);

      // Optionally, reset the quizEnded flag here if you plan to use it for multiple quizzes
      // setQuizEnded(false);
    }
  }, [quizEnded, correctAnswers, incorrectAnswers, octave, level, mode]);

  //End Quiz
  //stats call
  useEffect(() => {
    if (quizEnded) {
      const saveResults = async () => {
        const currentTime = new Date();
        const timestamp = formatTimestamp(currentTime);
        const quizData = {
          correctAnswers,
          incorrectAnswers,
          selectedOctaves: Array.isArray(octave) ? octave : [octave],
          selectedNotes: getNoteOptions(level),
          mode: mode,
          timestamp: currentTime,
          noteCount: noteCount,
        };

        await saveQuizResult(auth.currentUser.uid, quizData); // Assuming `auth.currentUser.uid` holds the current user's ID
      };

      saveResults();
      setResultsModalOpen(true);
    }
  }, [quizEnded, correctAnswers, incorrectAnswers, octave, level, mode,]);


  //end stats call

  //note labels 

  const midiNumberToNoteNameWithoutOctave = (midiNumber) => {
    const noteNames = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
    return noteNames[midiNumber % 12];
  };


  return (
    <Container>
      <WelcomeModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      <ResultsModal
        open={resultsModalOpen}
        onClose={() => {
          setResultsModalOpen(false);
          setQuizEnded(false); // Reset the flag indicating the quiz has ended.
        }}
        data={resultsData}
      />
      

      {/* Piano Rendering */}
      <div className={showLabels ? '' : 'hide-labels'}>
        <div className='piano-container'>
          {instrument ? (
            <>
              <div className="confetti-location">
                <Confetti active={confetti} config={config} />
              </div>

              <Piano
                noteRange={noteRange}
                playNote={midiNumber => {
                  instrument.play(midiNumber);
                  playChord(instrument, [midiNumber], true);
                }}
                stopNote={midiNumber => instrument.stop(midiNumber)}
                keyboardShortcuts={keyboardShortcuts}
                onPlayNoteInput={onPlayNoteInput}
                onStopNoteInput={onStopNoteInput}
                renderNoteLabel={({ midiNumber, isActive, isAccidental }) => {
                  const shortcut = keyboardShortcuts.find(s => s.midiNumber === midiNumber);
                  const noteName = midiNumberToNoteNameWithoutOctave(midiNumber);
                  const labelStyle = {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: '0.75rem',
                    color: isAccidental ? 'white' : 'black', // Adjust text color based on key type
                    width: '100%',
                    height: '100%'
                  };

                  return (
                    <div style={labelStyle}>
                      {showNoteNames && <div>{noteName}</div>}
                      {showLabels && <div style={{ fontSize: '0.65rem', marginTop: '5px' }}>{shortcut ? shortcut.key.toUpperCase() : ''}</div>}
                    </div>
                  );
                }}
              />


            </>
          ) : (
            <Box>
              <div className='piano-loader'><CircularProgress /></div>
            </Box>
          )}
        </div>
      </div>


      <SoundfontProvider
        instrumentName={selectedInstrument}
        audioContext={audioContext}
        soundfontHostname={soundfontHostname}
        onReady={setInstrument}
      />

      {/* Quiz Timer */}
      {quizRunning && <div className='timer'>Time remaining: {formatTime(timeLeft)}</div>}

      {/* Quiz Controls */}
      <div className='quiz-controls'>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {/* Start Quiz Button */}
          {!quizRunning && (
            <Grid item xs={12}>
              <Button
                fullWidth
                size="large"
                variant="contained"
                startIcon={<PlayCircleOutlineIcon fontSize="36px" />}
                onClick={startQuiz}
                sx={{ fontSize: '1.2rem', padding: '12px 24px' }}  // Adjust font size and padding as needed
              >
                Start
              </Button>
            </Grid>

          )}

          {/* settings Accordion */}
          {!quizRunning && (
            <Grid item xs={12}>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>🎛️ Settings</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid item spacing={2}>
                    {/* Level */}
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="level">Level</InputLabel>
                        <Select
                          value={getDisplayLevel(level)}
                          onChange={handleLevelChange}
                          label="Level"
                        >
                          <MenuItem value={1}>1 | C, D</MenuItem>
                          <MenuItem value={3}>2 | C, D, E</MenuItem>
                          <MenuItem value={5}>3 | C, D, E, F</MenuItem>
                          <MenuItem value={7}>4 | C, D, E, F, G</MenuItem>
                          <MenuItem value={9}>5 | C, D, E, F, G, A</MenuItem>
                          <MenuItem value={11}>6 | C, D, E, F, G, A, B</MenuItem>
                          <MenuItem value={13}>7 | C, D, E, F, G, A, B, F#</MenuItem>
                          <MenuItem value={15}>8 | C, D, E, F, G, A, B, F#, C#</MenuItem>
                          <MenuItem value={17}>9 | C, D, E, F, G, A, B, F#, C#, G#</MenuItem>
                          <MenuItem value={19}>10 | C, D, E, F, G, A, B, F#, C#, G#, D#</MenuItem>
                          <MenuItem value={21}>11 | C, D, E, F, G, A, B, F#, C#, G#, D#, A#</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {/* Sequential Mode */}
                    <Grid item xs={12}>
                      <FormControl>
                        <FormLabel id="mode-selection-label">Mode Selection</FormLabel>
                        <RadioGroup
                          aria-labelledby="mode-selection-label"
                          name="mode-selection-group"
                          value={mode}
                          onChange={handleModeChange}
                        >
                          <FormControlLabel value="sequential" control={<Radio />} label="Sequential Mode" />
                          <FormControlLabel value="chord" control={<Radio />} label="Chord Mode" />
                          <FormControlLabel value="default" control={<Radio />} label="Default Mode" />
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                    {/*Number of Note Selection */}
                    <Grid item xs={12}>
                      {/* Conditionally render the slider based on the mode */}
                      {mode === 'chord' || mode === 'sequential' ? (
                        <Box sx={{ width: 300 }}>
                          <Typography id="slider-label">
                            Number of Notes: {noteCount}
                          </Typography>
                          <Slider
                            aria-labelledby="slider-label"
                            value={noteCount}
                            onChange={updateNoteCount} // This will call the above function with the new value
                            step={1}
                            marks
                            min={mode === 'chord' ? 2 : 2}
                            max={mode === 'chord' ? 5 : 12}
                            valueLabelDisplay="auto"
                          />
                        </Box>
                      ) : null}
                    </Grid>

                    {/*Hide Show note labels*/}
                    <Grid item xs={12}>


                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={showLabels}
                              onChange={() => setShowLabels(prev => !prev)}
                              name="showKeyboardShortcuts"
                              color="primary"
                            />
                          }
                          label="Show Keyboard Shortcuts"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={showNoteNames}
                              onChange={() => setShowNoteNames(prev => !prev)}
                              name="showNoteNames"
                              color="primary"
                            />
                          }
                          label="Show Note Names"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={autoPlay} // Bind to autoPlay instead of showLabels
                              onChange={() => setAutoPlay(prevAutoPlay => !prevAutoPlay)} // Toggle autoPlay
                              name="autoPlay" // Relevant name
                              color="primary"
                            />
                          }
                          label="Autoplay next note"
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={showStreak} 
                              onChange={() => handleShowStreakChange(showStreak)} 
                              name="showingStreaks"
                              color="primary"
                            />
                          }
                          label="Show Streak"
                        />
                      </Grid>

                    </Grid>

                    {/* Octave Selector */}
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel id="octave">Octave(s)</InputLabel>
                        <Select value={octave} onChange={handleOctaveChange} label="Octave">
                          <MenuItem value="3">Low Voice</MenuItem>
                          <MenuItem value="4">High Voice</MenuItem>
                          <MenuItem value="3,4">High/Low Voice</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    {/*Instrument Selector */}
                    <Grid item xs={12}>
                      <PianoConfig
                        selectedInstrument={selectedInstrument}
                        onChangeInstrument={onChangeInstrument}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          )}

          {/* Quiz Running Controls */}
          {quizRunning && (
            <>
              <Grid container spacing={2} className="feedback-grid" justifyContent="center">
                {feedback.map((message, i) => (
                  <Grid item xs={12 / Math.min(feedback.length, 4)} sm={6} md={4} lg={3} key={i} className={feedbackStyle}>
                    <div className="feedbackMessage">{message}</div>
                  </Grid>
                ))}
              </Grid>

              {/* Replay Note and Next Note Buttons */}
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
              >

                {/* Replay Button */}
                <Grid item xs={6}>
                  <Button fullWidth variant="outlined" onClick={() => playChord(instrument, quizChord.notes, false)}>
                    Replay {showLabels ? "(Space Bar)" : ""}
                  </Button>
                </Grid>

                {/* Next Note Button */}
                <Grid item xs={6}>
                  <Button fullWidth variant="contained" onClick={handleReset} disabled={!isAnswered || autoPlay}>
                    Next Note {showLabels ? "(Enter)" : ""}
                  </Button>
                </Grid>
                {showStreak && (
                <Grid item xs={12}>
                  <NoteStreakDisplay noteStreak={statCorrectNoteStreak} />
                </Grid>
                )}
                {/* StatsComponent Display */}
                <Grid item xs={12}>
                  <StatsComponent
                    correctAnswers={correctAnswers}
                    incorrectAnswers={incorrectAnswers}
                    noteStreak={statCorrectNoteStreak}
                    winStreak={statWinStreak}
                  />
                </Grid>



              </Grid>


              {/* End Quiz Button */}
              <Grid item xs={12} sx={{ px: 0 }}> {/* No horizontal padding */}
                <Box mt={2} sx={{ width: '100%', px: 0 }}> {/* Ensure no horizontal padding */}
                  <Button
                    fullWidth
                    variant="outlined"
                    color="error"
                    onClick={endQuiz}
                    sx={{ width: '100%' }} // Ensure button takes full width of its parent
                    className='end-quiz-button'
                  >
                    End Quiz
                  </Button>
                </Box>
              </Grid>

            </>
          )}


        </Grid>
      </div>
    </Container>
  );
};

export default PianoChordQuiz;