import React, { useEffect, useState, useContext, } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Grid, Paper, TableContainer, TablePagination, Card, CardContent, Typography, } from '@mui/material';
import { getUserQuizStats } from '../utils/firebase/firebaseservices';
import { StatsContext } from '../contexts/StatsContext';
import { UserContext } from '../contexts/user.context';
import DashboardCharts from '../components/DashboardCharts';
import { Link } from 'react-router-dom';
import { signInWithGooglePopup } from '../utils/firebase/firebase.utils';
import { Modal, Button, Backdrop, Box, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import BadgeDisplay from '../components/BadgeDisplay';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";





const StatsPage = () => {
    const { currentUser } = useContext(UserContext); // Use UserContext to get the current user
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const { gamesPlayed, statMaxCorrectNoteStreak, highScore, quizzes, deleteQuiz } = useContext(StatsContext);

    const handleSignIn = async () => {
        await signInWithGooglePopup();
        // Additional logic after sign in (if needed)
    };

    const handleDeleteQuiz = async (quizId) => {
        await deleteQuiz(quizId);
        // No need to manually update the state here, it should automatically update due to the context change
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    // Calculate the average score based on fetched quizzes
    const totalScore = quizzes?.reduce((acc, quiz) => acc + (quiz.correctAnswers / (quiz.correctAnswers + quiz.incorrectAnswers || 1)) * 100, 0) ?? 0;
    const averageScore = quizzes?.length > 0 ? (totalScore / quizzes.length).toFixed(2) : '0';

    const getScoreEmoji = (score) => {
        const percentage = parseFloat(score);
        if (percentage === 100) return '💯';
        if (percentage >= 90 && percentage < 100) return '🟩';
        if (percentage >= 70 && percentage < 90) return '🟨';
        return '💩';
    };

    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
    };

    const [open, setOpen] = useState(!currentUser);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    if (!currentUser) {
        return (
            <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={10} md={8}>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Box sx={modalStyle}>
                            <Typography variant="h6" component="h2">
                                To save all your stats, please create an account or login
                            </Typography>
                            <Button variant="contained" color="primary" onClick={handleSignIn}>
                                Sign Up / Log In
                            </Button>
                        </Box>
                    </Modal>
                </Grid>
            </Grid>
        );
    }


    return (
        <Grid container justifyContent="center" spacing={2} sx={{ padding: { xs: 2, sm: 0 } }}>
            <Grid item xs={12} sm={10} md={8}>
                <Card>
                    <CardContent>
                        <Typography variant="h5" component="div">
                            Badges
                        </Typography>
                        <Typography variant="subtitle2" component="span" color="textSecondary" style={{ marginBottom: '58px' }}>
                            Earn badges by completing quizes. Answer more questions correctly to earn more badges.
                        </Typography>
                        <BadgeDisplay />
                    </CardContent>
                </Card>
            </Grid>
            
            
            <Grid item container xs={12} sm={10} md={8} justifyContent="space-around" spacing={2}>

                <Grid item xs={12} sm={4}>
                    <Card>
                        <CardContent>
                            <Grid container alignItems="flex-start" spacing={1}>
                                <Grid item>
                                    <Typography variant="h5" component="span">
                                        <span role="img" aria-label="star" style={{ fontSize: '1.25rem' }}>🏆</span>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" component="div">
                                        High Score
                                    </Typography>
                                    <Typography variant="h6">
                                        {highScore}%
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Card>
                        <CardContent>
                            <Grid container alignItems="flex-start" spacing={1}>
                                <Grid item>
                                    <Typography variant="h5" component="span">
                                        <span role="img" aria-label="star" style={{ fontSize: '1.25rem' }}>🎹</span>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" component="div">
                                        Games Played
                                    </Typography>
                                    <Typography variant="h6" component="span">
                                        {quizzes.length}
                                    </Typography>
                                    {' '}
                                    <Typography variant="subtitle2" component="span" color="textSecondary">
                                        (Avg. Score: {averageScore}%)
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <Card>
                        <CardContent>
                            <Grid container alignItems="flex-start" spacing={1}>
                                <Grid item>
                                    <Typography variant="h5" component="span">
                                        <span role="img" aria-label="star" style={{ fontSize: '1.25rem' }}>🔥</span>
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h5" component="div">
                                        Best Note Streak
                                    </Typography>
                                    <Typography variant="h6">
                                        {statMaxCorrectNoteStreak}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={10} md={8}>

                <Grid item xs={12}>
                    <DashboardCharts quizzes={quizzes} />
                </Grid>
            </Grid>
            
            <Grid item xs={12} sm={10} md={8}>
                <Paper>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Score</TableCell>
                                    <TableCell>Correct</TableCell>
                                    <TableCell>Incorrect</TableCell>
                                    <TableCell>Notes</TableCell>
                                    <TableCell>Octave(s)</TableCell>
                                    <TableCell>Mode</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Delete</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {quizzes?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((quiz, index) => {
                                    const score = ((quiz.correctAnswers / (quiz.correctAnswers + quiz.incorrectAnswers || 1)) * 100).toFixed(0);

                                    // Check if `timestamp` has `toDate` method, and if so, call it; otherwise, use `timestamp` directly
                                    const quizTimestamp = quiz.timestamp;
                                    let dateString;
                                    if (quizTimestamp && typeof quizTimestamp.toDate === 'function') {
                                        dateString = quizTimestamp.toDate().toLocaleString();
                                    } else if (quizTimestamp instanceof Date) {
                                        dateString = quizTimestamp.toLocaleString();
                                    } else {
                                        dateString = quizTimestamp; // Or however you wish to handle this case
                                    }

                                    return (
                                        <TableRow key={quiz.id || index}>
                                            <TableCell>{quizzes.length - index - (page * rowsPerPage)}</TableCell>
                                            <TableCell sx={{ width: '120px' }}>{score}% {getScoreEmoji(score)}</TableCell>
                                            <TableCell>{quiz.correctAnswers}</TableCell>
                                            <TableCell>{quiz.incorrectAnswers}</TableCell>
                                            <TableCell>
                                                {quiz.selectedNotes.length > 1
                                                    ? `${quiz.selectedNotes[0]}-${quiz.selectedNotes[quiz.selectedNotes.length - 1]}`
                                                    : quiz.selectedNotes[0] || ''}
                                            </TableCell>
                                            <TableCell>{quiz.selectedOctaves?.join(', ')}</TableCell>
                                            <TableCell>
                                                {quiz.mode} ({quiz.noteCount})
                                            </TableCell>

                                            <TableCell>{dateString}</TableCell>
                                            <TableCell>
                                                <IconButton aria-label="delete" color="error" size="small" onClick={() => handleDeleteQuiz(quiz.id)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>

                        </Table>

                    </TableContainer>
                    <TablePagination
                        component="div"
                        count={quizzes.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    <Typography variant="caption" display="block" style={{ marginTop: '10px', textAlign: 'center' }}>
                        Showing {quizzes.length} of {gamesPlayed} total games played. Games not show have been deleted.
                    </Typography>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default StatsPage;
