import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const instrumentMapping = {
    'acoustic_grand_piano': 'Piano',
    'acoustic_guitar_nylon': 'Classical Guitar',
    'banjo': 'Banjo',
    'alto_sax': 'Alto Sax',
    'cello': 'Cello',
    'electric_grand_piano': 'Electric Piano',
    'electric_guitar_clean': 'Electric Guitar',
    'flute': 'Flute',
    'string_ensemble_1': 'Strings',
    'tenor_sax': 'Tenor Sax',
    'trombone': 'Trombone',
    'trumpet': 'Trumpet',
    'violin': 'Violin'
};

export default function PianoConfig({ selectedInstrument, onChangeInstrument }) {
    return (
        <FormControl fullWidth>
            <InputLabel id="instrument-label">Instrument</InputLabel>
            <Select
                label="Instrument"
                labelId="instrument-label"
                value={selectedInstrument}
                onChange={onChangeInstrument}
            >
                {Object.keys(instrumentMapping).map((instrumentKey) => (
                    <MenuItem key={instrumentKey} value={instrumentKey}>
                        {instrumentMapping[instrumentKey]}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
