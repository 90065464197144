import React from 'react';
import { StatsProvider } from './contexts/StatsContext';
import './App.css';
import PianoChordQuiz from './PianoChordQuiz';
import Header from './components/Header';
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from 'react-router-dom';
import TagManager from 'react-gtm-module'
import StatsPage from './pages/StatsPage';
import AboutPage from './pages/AboutPage';
import SignIn from './routes/authentication/sign-in.component';
import Footer from './components/Footer';
import { Box } from '@mui/material';
import { DarkModeContext } from './contexts/DarkModeContext';
import getTheme from './theme';
import { useContext } from 'react';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { useMemo } from 'react';

const tagManagerArgs = {
  gtmId: 'GTM-M4TPNTTN'
}

TagManager.initialize(tagManagerArgs)
const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;



function About() {
  return (
    <div>
      <AboutPage />
    </div>
  );
}
function Stats() {
  return (
    <div>
      <StatsPage />
    </div>
  );
}

function Home() {
  return (
    <div className="App">
      <PianoChordQuiz />
    </div>
  );
}

function SignInPage() {
  return (
    <div className="App">
      <SignIn />
    </div>
  );
}

function App() {
  const { darkMode } = useContext(DarkModeContext);
  const theme = useMemo(() => getTheme(darkMode), [darkMode]);

  return (
    <ThemeProvider theme={theme}>
     <CssBaseline>
    <StatsProvider>
      <Router>
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <div>
          <div className='header-section'>
            <Header />
          </div>

          {/*Route Configuration */}
          <Routes>
            <Route path="/about" element={<About />} />
            <Route path="/stats" element={<Stats />} />
            <Route path="/" element={<Home />} />
            <Route path="/sign-in" element={<SignInPage />} />
          </Routes>
        </div>
        </Box>
        <div>
          <Footer />
        </div>
      
      </Router>
    </StatsProvider>
    </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
